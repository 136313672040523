import React from 'react'
import mGlass from '../../assets/images/mglass.png'
import "./style.css"

const Services = () => {
  return (
    <div className='services_container container'>
      
      <span className='services_text'>SERVICES</span>
      
        <div className='serv_flex_container'>
        <img  id="mglass" src={mGlass} alt="" />
        <div className='serv_flex_item0'></div>
        <div className='serv_flex_item1'>
          <span>Tele-Therapy</span>
          <span>Counseling</span>
          <span>Speech Therapy</span>
        </div>
        <div className='serv_flex_item2'>
          <span>Feeding</span>
          <span>Educational Games</span>
          <span>Occupational Therapy</span>
        </div>
        <div className='serv_flex_item3'>
          <span>Special Instructor</span>
          <span>Personal Care Assistant</span>
          <span>Physical Therapy</span>
        </div>
        </div>
      </div>
  )
}

export default Services