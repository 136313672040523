import React, { useState } from "react";
import img1 from "../../assets/images/client1.png";
import daisy from '../../assets/images/SVG/daisy.svg'
import sillyGlasses from '../../assets/images/SVG/sillyGlasses.svg'
import "./style.css"
import textData from './data.json'

const Slider = () => {
  const [showMore, setShowMore] = useState(false);
  const text = textData.text
  return (

    <div className="slider">
      <div className="div_0">
        <img id="daisy" src={daisy} alt="" />
        <img id="sillyGlasses" src={sillyGlasses} alt="" />
        <img className="roma_snapshot" src={img1} alt="Dad with an infant" />
      </div>
      <div className="div_1">
        <span className="image_title">
        Roman Marchuk 
        <p className="profession">Speech Language Pathologist</p>
      </span>
      <p className="bio_text">
        {showMore ? text : `${text.substring(0, 239)}`}
        <button className="btn" onClick={() => setShowMore(!showMore)}>{showMore ? " show less " : " show more "}</button>
      </p>

      <div className="">
        <h3 className="job_title_list" >Are you an SLP, OT, PT, SI or PCA?</h3>
        <div >
          <a href="/join-team" className="light_blue_join_btn">JOIN OUR TEAM</a>
        </div>
      </div>
      </div>
      


    </div>

  );
}

export default Slider;
