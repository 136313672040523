import React from "react";
import logo from "../../assets/images/logo/logo231x225.png";
import "./style.css"


export default function Nav() {
  return (
  <header className="header_container">
    <div>
      <a href="/"><img className="site-logo" src={logo}></img></a>
    </div>
    <div >
      <ul className="flex_nav">
      
        
        <li ><a id="a-1"href="/#info-page">HOW IT WORKS</a></li>
        <li ><a id="a-2"href="/#about-page">ABOUT US</a></li>
        <li id="a-3"><a href="/#apply">APPLY FOR SERVICES</a></li>
        <li id="a-4"><a href="/join-team">JOBS</a></li>
        
        

      </ul>
    </div>
   
  </header>
  );
}
