import React, { useState } from "react";
import arrow from "../../assets/images/triangle@3x.png";
import Requirements from "../Requirements/index.js";

export default function JoinTeamForm(props) {

    const [formFields, setFormFields] = useState({
        userName: "",
        address: "",
        emailOrPhone: "",
        profession: "Physical Therapist",
        files: {},
        fileName: "attach resume"
    })

    const [clrTest, setClrTest] = useState("")
    const [msg, setErrMsg] = useState("HAVE A CLEARANCE CODE?")
    let clrCode = "Welcome!"
    let [clrVal, setClrVal] = useState("")


    const submit = () => {
        console.log(clrVal)
        if (clrVal === clrCode) {
            setClrTest(clrVal)
        } else {
            setErrMsg("incorrect code entered.")
        }
    }

    const changeHandler = (event) => {
        const nam = event.target.name;
        const val = event.target.value;
      
        const setters = {
          clrcode: setClrVal,
          name: (val) => setFormFields((prev) => ({ ...prev, userName: val })),
          address: (val) => setFormFields((prev) => ({ ...prev, address: val })),
          emailOrPhone: (val) =>
            setFormFields((prev) => ({ ...prev, emailOrPhone: val })),
          profession: (val) =>
            setFormFields((prev) => ({ ...prev, profession: val })),
          upload: (val) => {
            const file = event.target.files && event.target.files[0];
            if(file) {
                setFormFields((prev) => ({ ...prev, files: file }));
                setFormFields((prev) => ({ ...prev, fileName: file.name }));
            }
          },
        };
      
        if (setters[nam]) {
          setters[nam](val);
        }
      };
 

    if (clrTest !== clrCode) {
        return (
            <div>
                <h1 className="text-4xl tracking-wide text-center pb-12" style={{ fontFamily: "Montserrat-medium" }}>JOIN OUR TEAM</h1>
                <form className="grid grid-cols-12" action="https://formsubmit.co/a4b9f435dec5c6f8e7741d4fa2e97a9a" enctype="multipart/form-data" method="POST">
                    <select onChange={changeHandler} name="profession" className="sm:col-start-4 sm:col-span-6 col-start-2 col-span-10 text-sm h-10 p-2 mb-6 border border-veryLightGrey " style={{ background: `url(${arrow}) no-repeat`, backgroundSize: 12, WebkitAppearance: 'none', backgroundPositionY: '14px', backgroundPositionX: '97%' }}>
                        <option defaultValue="Physical Therapist" >Physical Therapist</option>
                        <option value="Occupational Therapist">Occupational Therapist</option>
                        <option value="Personal Care Assistant">Personal Care Assistant</option>
                        <option value="Behavioral Support Specialist">Behavioral Support Specialist</option>
                        <option value="Speech-Language Pathologist">Speech-Language Pathologist</option>
                        <option value="Special Instructor">Special Instructor</option>

                    </select>
                    <div className="sm:col-start-4 sm:col-span-6 col-start-2 col-span-10">
                        <svg className="inline mr-2" xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19">
                            <path d="M6.303 19.001c-1.67-.008-3.265-.685-4.43-1.88-1.157-1.096-1.83-2.607-1.871-4.2-.041-1.592.553-3.136 1.65-4.29l7.36-7.43c.795-.793 1.879-1.226 3-1.2 1.202.004 2.351.49 3.19 1.35 1.722 1.657 1.789 4.39.15 6.13l-7.4 7.43c-.478.482-1.13.752-1.81.75-.733.001-1.437-.295-1.95-.82-1.055-1.022-1.09-2.704-.08-3.77l6.83-6.86c.402-.266.936-.209 1.271.136.336.346.377.88.1 1.274l-6.83 6.86c-.237.286-.202.708.08.95.14.142.33.224.53.23.149.002.293-.055.4-.16l7.39-7.43c.863-.959.796-2.433-.15-3.31-.878-.91-2.314-.976-3.27-.15l-7.39 7.39c-.726.78-1.111 1.817-1.07 2.88.041 1.065.506 2.07 1.29 2.79.786.815 1.867 1.28 3 1.29.976.008 1.915-.373 2.61-1.06l7.39-7.43c.389-.392 1.022-.394 1.415-.005.392.39.394 1.023.005 1.415l-7.39 7.43c-1.06 1.079-2.508 1.687-4.02 1.69z" />
                        </svg>
                        <label className=" inline hover:text-turquoiseBlue text-brightLightBlue cursor-pointer" htmlFor="file-upload-button">{formFields.fileName}</label>
                        <input onChange={changeHandler} id="file-upload-button" type="file" name="upload"></input>
                    </div>
                    <div className="sm:col-start-4 sm:col-span-6 col-start-2 col-span-10 grid grid-cols-2 mt-6">
                        <div className="flex flex-col mr-2">
                            <label className="text-xs mb-2"  >NAME</label>
                            <input onChange={changeHandler} className="p-2 border border-veryLightGrey" placeholder="John Smith" name="name" type="name" required></input>
                        </div>
                        <div className="flex flex-col ml-2">
                            <label className="text-xs mb-2">EMAIL/PHONE</label>
                            <input onChange={changeHandler} className=" p-2 border border-veryLightGrey" placeholder="email@example.com" type="email" name="emailOrPhone" required></input>
                        </div>
                    </div>
                    <label className="sm:col-start-4 sm:col-span-6 col-start-2 col-span-10 text-xs pt-6 pb-2">PREFERED WORK LOCATIONS</label>
                    <input onChange={changeHandler} type="zipcode" className="sm:col-start-4 sm:col-span-6 col-start-2 col-span-10 border border-veryLightGrey p-2" placeholder="zip codes" name="zipcodes" required></input>
                    <div className="sm:col-start-4 sm:col-span-4 col-start-2 col-span-10 mt-12 mb-12 text-sm"><input className="pl-10 pr-10 p-3 rounded bg-brightLightBlue hover:bg-turquoiseBlue" type="submit" value="SUBMIT"></input></div>

                </form>
                <div className="grid grid-cols-12">
                    <label className="sm:col-start-4 sm:col-span-4 col-start-2 col-span-10 mt-2 text-xs l">{msg}</label>

                    <input onChange={changeHandler} name="clrcode" type="password" className="sm:col-start-4 sm:col-span-2 col-start-2 col-span-6 mt-2 text-sm border border-veryLightGrey p-2"></input><button onClick={submit} className="bg-veryLightGrey w-24 mt-2 text-sm">UNLOCK</button>
                </div>
            </div>)
    } else {
        return <Requirements />;
    }
}
