import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {Footer, Nav} from './Components'
import "./App.css";


const WelcomePage = lazy(() => import ('./Pages/WelcomePage/index'));
const About = lazy(() => import ('./Pages/About/index'));
const HowItWorks = lazy(() => import ('./Pages/HowItWorks/index'));
const ApplyForServices = lazy(() => import ('./Pages/ApplyForServices/index'));
const Err = lazy(() => import ('./Pages/Err'));
const JoinTeam = lazy(() => import ('./Pages/JoinTeam/index'));

export default function App() {

  return (
    <div>
      <Suspense fallback="loading...">
        <Routes> 
      <Route path="*" element={<Err/>}/>
        <Route path="join-team" element={<><Nav/><JoinTeam/></>} />
        <Route path='/' element={<><WelcomePage/><div className="wrapper"><HowItWorks/><About/><ApplyForServices/><Footer/></div></>} />
    </Routes>
      </Suspense>
    
       
     
    </div>
  )
}
