import React from "react";
import logo from "../../assets/images/logo/logo231x225.png";

const Confirmation = () => {
  return (
    <div className="text-center mt-32">
      <img className="mx-auto" src={logo}></img>
      <h1 className="text-5xl" style={{ fontFamily: "Montserrat-medium" }}>
        THANKS
      </h1>
      <p className="p-8">We will contact you within 48 hours.</p>

      <a
        href="/"
        className="text-md tracking-wide text-brightLightBlue hover:text-turquoiseBlue"
      >
        HOME PAGE
      </a>
    </div>
  );
};

export default Confirmation;
