import React from "react";
import './style.css'

export default function Footer() {
  const year = new Date().getFullYear();

  return (
    <div className="footer">
      <div className="">Roman Marchuk © {year} Improve LLC</div>
      <div className="">
        <a href="" className=""></a>
      </div>
    </div>
  );
}
